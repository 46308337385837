.UserPassword {
    margin-top: 30px;
}

.SignInBtn {
    width: 100%;
    margin: 10px auto;
}
.SignInBtn span{
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    text-align: center;
    color: #4a4a4a;
}


.OrGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin:10px auto;
    color: #ffffff;
}

.OrLine {
    width: 157px;
    height: 2px;
    border: solid 1px rgba(255, 255, 255, 0.6);
}

.OrText {
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0;
    color: #ffffff;
    margin-top: -5px;
}
.captcha {
    margin: 0 auto 10px;
    width: 300px;
}